import React, { useEffect, useRef } from "react";
import ToastCalendar from "@toast-ui/react-calendar";
import { Box, Button, IconButton } from "@mui/material";
import { Typography } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import Dialog from "@mui/material/Dialog";
import EditEvent from "../components/common/EditEvent.js";
import { Add, CompareSharp } from "@mui/icons-material";
import AddEvent from "../components/common/AddEvent.js";

export default function Calendar(props) {
  const calendarRef = useRef(null);
  const [editEvent, setEditEvent] = React.useState(null);
  const [addEvent, setAddEvent] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [courses, setCourses] = React.useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      setEvents(await OpenPlannerAPI.getEvents());
      setCourses(await OpenPlannerAPI.getCourses());
    };
    fetchEvents();
  }, []);

  const handlePrevButton = () => {
    calendarRef.current.getInstance().prev();
    setMonth((month) => (month - 1) % 12);
  };
  const handleNextButton = () => {
    calendarRef.current.getInstance().next();
    setMonth((month) => (month + 1) % 12);
  };

  //Current time
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleExportCalendar = async () => {
    //Download the calendar from /calendar/export with axios
    OpenPlannerAPI.getExportICS().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "calendar.ics");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      {editEvent != null && (
        <Dialog open={true} onClose={() => setEditEvent(null)}>
          <EditEvent
            event={editEvent}
            onSubmit={() => {
              window.location.reload();
              setEditEvent(null);
            }}
          />
        </Dialog>
      )}
      {addEvent != null && (
        <Dialog open={true} onClose={() => setEditEvent(null)}>
          <AddEvent
            event={addEvent}
            onSubmit={() => {
              window.location.reload();
              setEditEvent(null);
            }}
          />
        </Dialog>
      )}
      {/* current month */}
      <IconButton onClick={handlePrevButton}>
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton onClick={handleNextButton}>
        <ArrowForwardIosIcon />
      </IconButton>
      <Typography variant="h5" sx={{ marginLeft: 3 }} component="b">
        {monthNames[month]}
      </Typography>
      <Box sx={{ float: "right", marginRight: "250px" }}>
        <Button onClick={handleExportCalendar} variant="contained">
          Export Calendar
        </Button>
      </Box>
      <ToastCalendar
        ref={calendarRef}
        height="800px"
        view="month"
        scrollToNow={true}
        navigation={true}
        useScrollDirection={true}
        usageStatistics={false}
        width="auto"
        onBeforeCreateEvent={(e) => {
          console.log(e);
        }}
        onClickEvent={(e) =>
          setEditEvent({ calendar: e.event.calendarId, ...e.event })
        }
        onSelectDateTime={(e) => {
          setAddEvent({ start: e.start, end: e.end });
        }}
        events={events.map((event) => {
          return {
            ...event,
            title: event.body,
            calendarId: event.calendar,
          };
        })}
        calendars={courses.map((course) => {
          return {
            id: course.id,
            name: course.name,
            color: "black",
            backgroundColor: course.color,
            dragBgColor: course.color,
            borderColor: course.color,
          };
        })}
      />
    </>
  );
}
