import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import OpenPlanner from "./OpenPlannerAPI";
import convertToDateTimeLocal from "./convertToDateTimeLocal";
import { AddToCalendarButton } from "add-to-calendar-button-react";

function ShareEvent({ event }) {
  return (
    <add-to-calendar-button
      name={event.body}
      startDate={event.start.split("T")[0]}
      startTime={event.start.split("T")[1].substring(0, 5)}
      endTime={event.end.split("T")[1].substring(0, 5)}
      endDate={event.end.split("T")[0]}
      timeZone="America/Los_Angeles"
      description={event.title}
      options="'Apple','Google','Outlook.com'"
      buttonsList
      hideTextLabelButton
      buttonStyle="round"
      lightMode="bodyScheme"
    ></add-to-calendar-button>
  );
}

export default function EditEvent({ event, onSubmit }) {
  const [formData, setFormData] = useState({
    id: event.id,
    start: convertToDateTimeLocal(event.start),
    end: convertToDateTimeLocal(event.end),
    title: event.title,
    body: event.body,
    calendar: event.calendar,
    isCompleted: event.isCompleted,
  });

  if (!event) return null;
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset form
    await OpenPlanner.updateEvent({
      ...formData,
    });
    setFormData({
      id: "",
      start: "",
      end: "",
      title: "",
      body: "",
      calendar: "",
      isCompleted: false,
    });

    onSubmit();
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Edit Event
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Start"
          name="start"
          type="datetime-local"
          value={formData.start}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="End"
          name="end"
          type="datetime-local"
          value={formData.end}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Title"
          name="body"
          value={formData.body}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Body"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={onSubmit}>Cancel</Button>
          <ShareEvent event={formData} />
        </Box>
      </form>
    </Box>
  );
}
