// App.js
import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Grid2 as Grid,
  Paper,
  Box,
} from "@mui/material";

function HeroSection() {
  return (
    <Grid container sx={{ minHeight: "90vh", marginTop: 10 }}>
      <Grid
        size={{ xs: 11, md: 4 }}
        offset={{ xs: 1, md: 2 }}
        sx={{
          marginTop: 20,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            color: "#313131",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Student Educator Calendar{" "}
        </Typography>
        <Typography variant="h6" color="textSecondary" sx={{ marginBottom: 4 }}>
          Spend less time planning and more time doing with a shareable calendar
          that works for students and educators.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: 2, paddingX: 4, paddingY: 1.5 }}
          href="/login"
        >
          Sign In
        </Button>
        <Button
          variant="outlined"
          color="primary"
          href="/login"
          sx={{ paddingX: 4, paddingY: 1.5 }}
        >
          Sign Up
        </Button>
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{
          background: "url(/calendar-preview.jpg)",
          marginBottom: 10,
          marginTop: 10,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          minHeight: 600,
        }}
      ></Grid>
    </Grid>
  );
}

function FeaturesSection() {
  return (
    <Grid container>
      <Grid
        size={{ xs: 12, md: 5 }}
        offset={{ md: 1 }}
        sx={{
          background: "url(/events.jpg)",
          marginBottom: 10,
          marginTop: 10,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: 600,
        }}
      ></Grid>
      <Grid
        container
        sx={{
          textAlign: "center",
          marginTop: 6,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={5} sx={{ marginTop: 10 }} offset={{ md: 1 }}>
          <Typography
            variant="h5"
            sx={{
              marginTop: 0,
              color: "#313131",
              fontSize: 30,
              marginBottom: "400px",
              fontFamily: "Roboto, sans-serif",
              padding: 10,
            }}
          >
            Easily see events and deadlines in one place
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Home() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#fff",
          color: "#000",
          boxShadow: "none",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
            OpenPlanner
          </Typography>
          <Button href="/login" color="inherit">
            Sign In
          </Button>
        </Toolbar>
      </AppBar>
      <HeroSection />
      <FeaturesSection />
    </Box>
  );
}

export default Home;
