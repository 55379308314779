import React, { useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Dialog,
  ListItemText,
  Paper,
} from "@mui/material";
import EditEvent from "../common/EditEvent";

export default function EventsList({ events, title, courses }) {
  const [editEvent, setEditEvent] = React.useState(null);

  return (
    <Box marginBottom={3}>
      {editEvent != null && (
        <Dialog open={true} onClose={() => setEditEvent(null)}>
          <EditEvent
            event={editEvent}
            onSubmit={() => {
              window.location.reload();
              setEditEvent(null);
            }}
          />
        </Dialog>
      )}
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {events.length > 0 ? (
        events.map((event) => (
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              setEditEvent(event);
            }}
            sx={{
              backgroundColor: "#f1f1f1",
              "&:hover": {
                background: "#cbc9c9",
              },
              cursor: "pointer",
              minHeight: "70px",
              width: "100%",
              overflow: "hidden",
              alignItems: "stretch",
              marginBottom: "8px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#000",
                  fontWeight: "bold",
                  width: 120,
                  padding: 1,
                }}
              >
                {event.start.split("T")[1].slice(0, 5)} -{" "}
                {event.end.split("T")[1].slice(0, 5)}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: courses?.find(
                  (course) => course.id === event.calendar
                )?.color,
                width: "5px",
                minWidth: "5px",
                maxWidth: "5px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            ></Box>
            <Box
              alignItems="center"
              sx={{
                marginRight: "auto",
                color: "#000",
              }}
            >
              <Box sx={{ padding: 1 }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.body.replace("\n", "<br/>"),
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="body1">
          You have no events due today. Yayyy!
        </Typography>
      )}
    </Box>
  );
}
