import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EventsList from "../components/Overview/EventsList.js";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import CourseLegend from "../components/Overview/CourseLegend.js";
import sortEventsByDay from "../components/common/sortEventsByDay.js";

const formatDueDate = (dateString) => {
  const options = { weekday: "long", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export default function Overview(props) {
  const [events, setEvents] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      setCourses(await OpenPlannerAPI.getCourses());
      let data = await OpenPlannerAPI.getEvents();
      const today = new Date();

      data.sort((a, b) => {
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        return Math.abs(dateA - today) - Math.abs(dateB - today);
      });

      if (data) setEvents(data);
      setLoading(false);
    };
    fetchEvents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const eventsByDay = sortEventsByDay(events);
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Overview
      </Typography>
      <CourseLegend courses={courses} />
      <EventsList
        events={
          eventsByDay.length &&
          new Date(eventsByDay[0][0].start).toDateString() ===
            new Date().toDateString()
            ? eventsByDay[0]
            : []
        }
        title="Due Today"
        courses={courses}
      />
      {eventsByDay.slice(0, 3).map((events, index) => {
        if (
          new Date(events[0].start).toDateString() === new Date().toDateString()
        )
          return null;
        return (
          <EventsList
            events={events}
            courses={courses}
            title={`Due on ` + formatDueDate(events[0].start)}
          />
        );
      })}
    </Box>
  );
}
