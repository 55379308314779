/*
Return dates after today in sorted order
*/
export default function sortEventsByDay(sortedEvents) {
  const eventsByDay = [];
  let i = 0;
  while (i < sortedEvents.length) {
    let currDay = sortedEvents[i].start;
    if (
      new Date(currDay).toISOString().slice(0, 10) <
      new Date().toISOString().slice(0, 10)
    ) {
      i++;
      continue;
    }
    let currEvents = [];
    while (i < sortedEvents.length && sortedEvents[i].start === currDay) {
      currEvents.push(sortedEvents[i]);
      i++;
    }
    eventsByDay.push(currEvents);
    i++;
  }
  return eventsByDay;
}
