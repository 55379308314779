import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Link } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "../style/Dashboard.css";
import "@aws-amplify/ui-react/styles.css";
import { Card, CardContent, Typography, CardMedia, Box } from "@mui/material";
import OpenPlanner from "../components/common/OpenPlannerAPI";
import UploadCard from "../components/Courses/UploadCard";
import FileProgressSnackBar from "../components/Courses/FileProgressSnackBar";

function ClassCard(props) {
  //Display a card with class name
  return (
    <Link to={props.link} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          width: 345,
          height: 300,
          margin: "20px",
        }}
      >
        <CardMedia
          sx={{
            height: 200,
            backgroundColor: props.color,
          }}
          alt={props.name}
        />
        <CardContent>
          <Typography variant="h5" component="div" color="textPrimary">
            {props.name}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

export default function Courses(props) {
  const [courses, setCourses] = React.useState([]);
  const [filesUploadState, setFilesUploadState] = React.useState([]);
  const [showFileProgress, setShowFileProgress] = React.useState(false);
  useEffect(() => {
    async function fetchData() {
      setCourses(await OpenPlanner.getCourses(false));
    }
    setInterval(() => {
      if (filesUploadState.length !== 0) fetchData();
    }, 1000);
    fetchData();
  }, []);

  useEffect(() => {
    //Check if course in filesUploadState
    let copy = [...filesUploadState];
    filesUploadState.forEach((fileState) => {
      if (courses.find((course) => course.id === fileState.id)) {
        copy.find((file) => file.id === fileState.id).state = "Upload Complete";
      }
    });
  }, [courses]);
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "left",
      }}
    >
      {courses.map((course, index) => (
        <ClassCard
          key={index}
          name={course.name}
          color={course.color}
          link={`/Course/${course.id}`}
        />
      ))}
      <UploadCard
        handleChange={async (file) => {
          setShowFileProgress(true);
          let fileState = {
            state: "Uploading",
            id: null,
            name: file.name,
          };
          let copy = [...filesUploadState, fileState];
          let id = copy.length - 1;
          setFilesUploadState(copy);
          let response = await OpenPlanner.uploadSyllabus(file);
          copy = [...filesUploadState];
          copy[id] = {
            state: "Upload Complete",
            id: response.id,
            name: file.name,
          };
          setFilesUploadState(copy);
        }}
      />
      {showFileProgress && (
        <FileProgressSnackBar
          handleClose={() => {
            setShowFileProgress(false);
          }}
          filesUploadState={filesUploadState}
        />
      )}
    </Box>
  );
}
