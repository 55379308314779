import React from "react";
import { Box } from "@mui/material";
import NavBar from "./NavBar";
function Container({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          height: "100vh",
          width: "150px",
          backgroundColor: "#670083",
          padding: "16px",
        }}
      >
        <NavBar />
      </Box>
      <Box
        sx={{
          position: "relative",
          left: "150px",
          width: "calc(100% - 150px)",
          padding: "16px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Container;
