import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import OpenPlanner from "./OpenPlannerAPI";
import convertToDateTimeLocal from "./convertToDateTimeLocal";

export default function AddEvent({ calendar, onSubmit, event }) {
  const [formData, setFormData] = useState({
    start: event?.start ? convertToDateTimeLocal(event.start) : "",
    end: event?.end ? convertToDateTimeLocal(event.end) : "",
    title: event?.title || "",
    body: event?.body || "",
    calendar: calendar,
    isCompleted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset form
    await OpenPlanner.addEvent({
      ...formData,
    });
    setFormData({
      start: "",
      end: "",
      title: "",
      body: "",
      calendar: "",
    });

    onSubmit();
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Add Event
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Start"
          name="start"
          type="datetime-local"
          value={formData.start}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="End"
          name="end"
          type="datetime-local"
          value={formData.end}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Title"
          name="body"
          value={formData.body}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Body"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Save
        </Button>{" "}
        <Button onClick={onSubmit}>Cancel</Button>
      </form>
    </Box>
  );
}
